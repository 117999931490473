
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.hideExclamation !== true) {str += '<span class="fa fa-exclamation" data-tooltip="Filter is inversed."></span>';}str += '';if (d.hideCheck !== true) {str += '<span class="fa';if (d.active) {str += ' fa-check-square';} else {str += ' fa-square-o';}str += '"></span>';}str += '<span class="name filter-name">';str += _CfS(d.name);str += '</span>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
