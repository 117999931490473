
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<ul class="share-buttons">'+'  <li><a href="mailto:?subject=Get%202%20months%20of%20';str += _CfS(BRAND.NAME);str += '%20free!&body=Sign%20up%20for%20a%20';str += _CfS(BRAND.NAME);str += '%20account%20using%20this%20link%20to%20receive%20a%20special%20referral%20discount%20';str += _CfS(d.user.shareLinks.emailShareLink);str += '" class="email-share-link" data-tooltip="Share via Email"><span class="fa fa-envelope"></span></a></li>'+'</ul>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
