import * as LoadingIconPolaroidTemplate from 'app/client/settingsShared/views/templates/loadingIconPolaroid.html';import Loading from 'app/client/core/js/modules/loading.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Loading'], Loading]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="image-browser clearfix">'+'  ';if (d.files.length) {str += '    <h4>Your Workspace\'s Icons:</h4>'+'    <div class="image-container">'+'      ';_ds.push(d); var _d1 = d.files; for (var _i1 = 0, _len1 = (d.files || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var icon = _d1[_i1];str += '        ';str += LoadingIconPolaroidTemplate.render( icon);str += '      ';} d = _ds.pop();str += '    </div>'+'  ';} else {str += '    ';var defaultLoadingState = App.Loading.getDefaultLoadingState();str += '    <h4>Default Loading Icon:</h4>'+'    <div class="image-column image-column-1">'+'      <div class="polaroid">'+'        <div class="polaroid-background">'+'          <span class="image-wrapper fa"><img src="';str += _CfS(defaultLoadingState.url);str += '" alt="';str += _CfS(defaultLoadingState.alt || '');str += '" /></span>'+'          <div class="message filename">';str += _CfS(defaultLoadingState.message);str += '</div>'+'        </div>'+'      </div>'+'    </div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
