import TokenModel from 'app/client/core/js/models/token.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Token'], TokenModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';if (d.tokens.length > 0) {str += '  <div class="existing-api-tokens">'+'    <table class="table" border="0" cellpadding="0" cellspacing="0">'+'      <tr>'+'        <th>Name</th>'+'        <th>Last Used</th>'+'        <th>&nbsp;</th>'+'      </tr>'+'      ';_ds.push(d); var _d1 = d.tokens; for (var _i1 = 0, _len1 = (d.tokens || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;d = _d1[_i1];str += '        <tr data-model="Token" data-id="';str += _CfS(d.id);str += '">'+'          <td>';str += _CfS(d.description);str += '</td>'+'          <td>';if (d.formatted_last_used) {str += _CfS(d.formatted_last_used);} else {str += '<em>Never</em>';}str += '</td>'+'          <td class="actions-column"><a href="#" class="delete-token" data-on-click="deleteToken" data-id="';str += _CfS(d.id);str += '"><span class="fa fa-trash-o"></span></a></td>'+'        </tr>'+'      ';} d = _ds.pop();str += '    </table>'+'  </div>';}str += '<form method="post" data-on-submit="createToken">'+'  <div class="form-input" data-validate="notEmpty">'+'    <label>Token Name</label>'+'    <input type="text" name="description" spellcheck="false" placeholder="My API Token" data-on-keyup="enableSubmitButton" class="textfield" data-tabindex />'+'  </div>'+''+'  <div>'+'    <button id="generate-token-button" class="action green disabled" data-on-click="createToken" data-tabindex disabled>Generate Token</button>'+'  </div>'+'</form>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
