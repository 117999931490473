
export function render(_d) {
  let html = '<button type="button" data-on-click="close" class="action mini flat-white">Cancel</button><button type="submit" data-on-click="save" class="action mini green" data-tabindex>Create Label <span class="fa fa-arrow-right"></span></button>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
