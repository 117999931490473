import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="user-autocomplete">'+'  ';str += UserAvatarTemplate.render( { profile: d, size: 26 });str += '  <span class="username">@';str += _CfS(d.mention_name);str += '</span>'+'  <span class="name">';str += _CfS(d.name);str += '</span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
