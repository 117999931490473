
export function render(_d) {
  let html = '<div class="saving"><span class="fa fa-star fa-spin"></span> Saving...</div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
