import EmojiPickerController from 'app/client/core/js/controllers/emojiPicker.js';
import Emoji from 'app/client/core/js/modules/emoji.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'EmojiPicker'], EmojiPickerController],
  [['Emoji'], Emoji],
  [['Controller', 'EmojiPicker'], EmojiPickerController],
  [['Emoji'], Emoji]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<strong id="emoji-shortname"></strong>'+'<span class="dropdown-title">Skin tones: </span>';_ds.push(d); var _d0 = d; for (var _i0 = 0, _len0 = (d || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '  <a href="#" class="skin-tone-toggle';if (d.active) {str += ' selected';}str += '" data-controller="EmojiPicker" data-on-click="toggleSkinTone" data-tone="';str += _CfS(d.value);str += '">';str += (d.name);str += '</a>';} d = _ds.pop();str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
