import SystemAlertsController from 'app/client/core/js/controllers/systemAlerts.js';
import Format from 'app/client/core/js/modules/format.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'SystemAlerts'], SystemAlertsController],
  [['Format'], Format],
  [['Controller', 'SystemAlerts'], SystemAlertsController],
  [['Format'], Format]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div id="';str += _CfS(d.id);str += '" class="';str += _CfS(d.classNames);str += '">'+'  <div class="system-notification ';str += _CfS(d.severity);str += '">'+'    <span class="fa ';if (d.severity === 'low' || d.severity === 'info') {str += 'fa-info-circle';} else {str += 'fa-exclamation-triangle';}str += '"></span>'+'    <span class="banner-message"';if (d.onClick) {str += ' data-on-click="';str += _CfS(d.onClick);str += '"';}str += '>';str += (App.Format.markdownify(d.message, 'notification-banner', { inline: true }));str += '</span>'+'    ';if (d.url) {str += '      <span class="banner-link">See <a href="';str += _CfS(d.url);str += '" target="_blank">';str += _CfS(d.url);str += '</a>.</span>'+'    ';}str += '    ';if (d.dismissible) {str += '      <a href="" data-controller="SystemAlerts" data-on-click="closeBanner" class="close-banner"><span class="fa fa-times"></span></a>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
