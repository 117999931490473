
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="tooltip ';str += _CfS(d.extraClass);str += ' tooltip-align-';str += _CfS(d.align);str += '">'+'  <div class="tooltip-arrow"><span class="fa ';if (d.align === 'top') {str += 'fa-caret-down';} else if (d.align === 'bottom') {str += 'fa-caret-up';}str += '"></span></div>'+'  <div class="tooltip-contents" ';if (d.maxWidth) {str += 'style="max-width: ';str += _CfS(d.maxWidth);str += 'px;"';}str += '>';str += (d.html);str += '</div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
