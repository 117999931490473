import InlineMessageController from 'app/client/core/js/controllers/inlineMessage.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'InlineMessage'], InlineMessageController],
  [['Controller', 'InlineMessage'], InlineMessageController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="inline-message" id="';str += _CfS(d.id);str += '">'+'  <span class="message-icon fa ';str += _CfS(d.icon);str += '"></span>'+'  ';if (!d.noCancel) {str += '    <a href="#" class="fa fa-times" data-controller="InlineMessage" data-on-click="close"></a>'+'  ';}str += '  <div class="text">'+'    ';str += (d.html);str += '    ';if (d.actions) {str += '      <div class="actions">';str += (d.actions);str += '</div>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
