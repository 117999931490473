import * as StoryRelationshipTemplate from 'app/client/core/views/templates/storyRelationship.html';import StoryController from 'app/client/core/js/controllers/story.js';
import StoryModel from 'app/client/core/js/models/story.js';
import StoryLinkModel from 'app/client/core/js/models/storyLink.js';
import Format from 'app/client/core/js/modules/format.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Story'], StoryController],
  [['Model', 'Story'], StoryModel],
  [['Model', 'StoryLink'], StoryLinkModel],
  [['Format'], Format],
  [['Controller', 'Story'], StoryController],
  [['Model', 'Story'], StoryModel],
  [['Model', 'StoryLink'], StoryLinkModel],
  [['Format'], Format]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var allLinks = App.Model.StoryLink.getLinksForStory(d);str += '';var links = _.reject(allLinks, App.Model.StoryLink.isArchived);str += '';var archivedLinks = _.filter(allLinks, App.Model.StoryLink.isArchived);str += '';if (links.length > 0) {str += '  <ul>'+'    ';_ds.push(d); var _d1 = links; for (var _i1 = 0, _len1 = (links || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var link = _d1[_i1];str += '      ';str += StoryRelationshipTemplate.render( link);str += '    ';} d = _ds.pop();str += '  </ul>';} else if (d.readOnly) {str += '  <p class="none-found">No active relationships.</p>';}str += '';if (archivedLinks.length > 0) {str += '  <div class="archived-relationships">'+'    <div class="archived-story-links ';if (d.showingArchivedStories) {str += 'is-open';} else {str += 'is-closed';}str += '">'+'      <ul>'+'        ';_ds.push(d); var _d1 = archivedLinks; for (var _i1 = 0, _len1 = (archivedLinks || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var link = _d1[_i1];str += '          ';str += StoryRelationshipTemplate.render( link);str += '        ';} d = _ds.pop();str += '      </ul>'+'    </div>'+'    <a href="#" class="toggle-archived-story-links" data-on-click="toggleArchivedStoryLinks" data-controller="Story">';if (d.showingArchivedStories) {str += 'Hide';} else {str += 'Show';}str += ' ';str += _CfS(App.Format.pluralize(archivedLinks.length, 'archived related story', 'archived related stories'));str += '</a>'+'  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
