import PanelController from 'app/client/core/js/controllers/panel.js';
import PanelModel from 'app/client/core/js/models/panel.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Panel'], PanelController],
  [['Model', 'Panel'], PanelModel],
  [['Controller', 'Panel'], PanelController],
  [['Model', 'Panel'], PanelModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div'+'  id="panel-';str += _CfS(d.id);str += '"'+'  class="panel-container ';if (d.expanded) {str += 'expanded';}str += ' ';if (d.center) {str += 'panel-center';}str += '"'+'  data-controller="Panel"'+'  data-model="Panel"'+'  data-id="';str += _CfS(d.id);str += '"'+'  data-on-mousedown="onMouseDownOutside"'+'  ';if (d.aria.role) {str += '    role="';str += _CfS(d.aria.role);str += '"'+'    aria-labelledby="';str += _CfS(d.aria.labelledby);str += '"'+'    aria-describedby="';str += _CfS(d.aria.describedby);str += '"'+'    aria-modal="true"'+'  ';}str += '>'+'  <div class="panel">'+'    <div id="';str += _CfS(d.id);str += '-portals" class="dialog-content-portals"></div>'+''+'    ';if (d.showCloseButton) {str += '    <button'+'      class="close-button fa fa-times"'+'      data-on-click="close"'+'      data-tooltip="Close"'+'      data-tooltip-shortcut="(esc)"'+'      aria-label="Close"'+'    ></button>'+'    ';}str += ' ';if (d.html) {str += '    <div class="panel-content">';str += (d.html);str += '</div>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
