
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += 'Show Progress and Metrics Using'+'  <a href="#" data-on-click="updateReportingMetrics" class="action micro white" data-using-points="';str += _CfS(!!d.using_estimates);str += '">'+'    ';if (d.using_estimates) {str += '      Estimate Points'+'    ';} else {str += '      Story Count (default)'+'    ';}str += '    <span class="fa fa-caret-down"></span>'+'  </a>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
