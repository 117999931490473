import ColumnModel from 'app/client/core/js/models/column.js';
import EstimateModel from 'app/client/core/js/models/estimate.js';
import EstimateScaleModel from 'app/client/core/js/models/estimateScale.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import ColumnController from 'app/client/stories/js/controllers/column.js';
import SidebarController from 'app/client/stories/js/controllers/sidebar.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Column'], ColumnModel],
  [['Model', 'Estimate'], EstimateModel],
  [['Model', 'EstimateScale'], EstimateScaleModel],
  [['Tooltip'], Tooltip],
  [['Controller', 'Column'], ColumnController],
  [['Controller', 'Sidebar'], SidebarController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<div class="column-list-nav unselectable" data-controller="Column">'+'  ';var displayUsingPoints = App.Model.EstimateScale.isUsingPoints();str += '  ';_ds.push(d); var _d0 = d.columns; for (var _i0 = 0, _len0 = (d.columns || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var column = _d0[_i0];str += '    <div data-id="';str += _CfS(column.id);str += '" class="column-selector-parent relative ';str += _CfS(column.class_name);str += '">'+'      <div class="list-nav-item column-selector ';if (column.active) {str += 'active';}str += ' ';if (column.archived) {str += 'archived';}str += '" data-model="Column" data-id="';str += _CfS(column.id);str += '" data-on-click="toggleActiveState" data-context-menu="contextMenuItems" id="sidebar-column-';str += _CfS(column.id);str += '" ';if (column.name.length > 16) {str += 'data-tooltip data-tooltip-fn="App.Controller.Column.columnSidebarTooltip"';}str += '>'+'        ';if (column.active) {str += '          <span class="fa fa-check-square"></span>'+'        ';} else {str += '          <span class="fa fa-square-o"></span>'+'        ';}str += '        ';str += (App.Controller.Sidebar.renderStatusIcon(column.id, column.type));str += '        <span class="name">';str += _CfS(column.name);str += '</span>'+'      </div>'+'    </div>'+'  ';} d = _ds.pop();str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
