import StoriesStoryController from 'app/client/stories/js/controllers/storiesStory.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'StoriesStory'], StoriesStoryController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="load-old-stories" data-workflow-state-id="';str += _CfS(d.workflowStateId);str += '">'+'  ';if (d.isLoading === true) {str += '  <button class="action mini disabled"><span class="fa fa-spinner fa-spin"></span>Loading...</button>'+'  ';} else {str += '  <button'+'    class="action mini ';str += _CfS(d.buttonClass ? d.buttonClass : 'flat-white' );str += '"'+'    data-tooltip="Stories that were moved to done over 30 days ago are not loaded by default to keep the UI fast."'+'    data-controller="StoriesStory"'+'    data-on-click="loadOldStories"'+'  >'+'    Load 30 More Days<span class="fa fa-question-circle"></span>'+'  </button>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
