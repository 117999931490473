import MiroController from 'app/client/core/js/controllers/miro.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Miro'], MiroController],
  [['Controller', 'Miro'], MiroController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var url = d.url;str += '<div class="miro-container">'+'  <div class="miro-title"> '+'    <span class="miro-title-type">Miro:</span> '+'    <a class="title-link ';str += _CfS( d.boardId );str += '" href="';str += _CfS( url );str += '" target="_blank" rel="noreferrer noopener">';str += _CfS( App.Controller.Miro.renderEmbedTitle(url) );str += '</a>'+'    <a href="#" class="toggle-preview-link ';if (d.showPreviews) {str += 'hide-miro-previews';} else {str += 'show-miro-previews';}str += ' ';if (d.hideToggle) {str += 'hide-toggle';}str += '" data-controller="Miro" data-on-click="togglePreviews" data-tooltip="';if (d.showPreviews) {str += 'Hide Preview';} else {str += 'Show Preview';}str += '" data-source="';str += _CfS( d.source );str += '" data-board-id="';str += _CfS( d.boardId );str += '">'+'      <span class="fa ';if (d.showPreviews) {str += 'fa-caret-down';} else {str += 'fa-caret-right';}str += '"></span>'+'    </a>'+'  </div>'+'  <div class="miro-link">'+'    <a href="';str += _CfS(url);str += '" target="_blank" rel="noreferrer noopener">';str += _CfS(url);str += ' '+'      <span class="fa fa-external-link"></span>'+'    </a>'+'  </div>'+'  <iframe class="miro-iframe ';if (!d.showPreviews) {str += 'collapsed';}str += '" src="';str += _CfS(d.iframeSrc);str += '" allowfullscreen referrerpolicy="no-referrer"></iframe>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
