import * as ApproveOrDenyTemplate from 'app/client/oauth/views/templates/approveOrDeny.html';
import * as AuthOrgSelectorTemplate from 'app/client/oauth/views/templates/authOrgSelector.html';
import * as InlineUserMentionTemplate from 'app/client/core/views/templates/inlineUserMention.html';import ApplicationModel from 'app/client/core/js/models/application.js';
import OauthPageController from 'app/client/oauth/js/controllers/oauthPage.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Application'], ApplicationModel],
  [['Controller', 'OauthPage'], OauthPageController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="application" data-controller="OauthPage" data-model="Application" data-id="';str += _CfS(d.application.id);str += '">'+'  <div class="logos">'+'    <img class="invert-for-dark-mode" src="';str += _CfS(d.application.logo);str += '" alt="';str += _CfS(d.application.name);str += '" />'+'    <span class="fa fa-plug"></span>'+'  </div>'+'  <p><strong>';str += _CfS(d.application.name);str += '</strong> would like to your permission to connect with <strong>';str += _CfS( BRAND.NAME );str += '</strong>.</p>'+''+'  <form action="';str += _CfS(d.endpoint);str += '" method="post" id="authorize-form">'+'    ';if (d.organizations.length > 1 || !d.installation) {str += '      <div id="org-selector">'+'        ';str += AuthOrgSelectorTemplate.render( d);str += '      </div>'+'      <div id="install-action"></div>'+'    ';} else {str += '      <div id="install-action">'+'        ';str += ApproveOrDenyTemplate.render( { organization: d.organizations[0], installation: d.installation, credential: d.credential, redirectUri: d.redirectUri, redirectState: d.redirectState });str += '      </div>'+'    ';}str += '  </form>'+''+'  <div id="installation-instructions"></div>'+''+'  <div class="auth-details">'+'    <p>You are currently logged in as <strong>';str += _CfS(d.profile.name);str += '</strong> (<span class="mention-name">';str += InlineUserMentionTemplate.render( d);str += '</span>).</p>'+'    <h4>The app will be able to:</h4>'+'    <ul>'+'      <li>Read your Stories</li>'+'      <li>Create and update Stories</li>'+'    </ul>'+'    <h4>It won\'t be able to:</h4>'+'    <ul>'+'      <li>Read your email address</li>'+'      <li>See your ';str += _CfS( BRAND.NAME );str += ' password</li>'+'    </ul>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
