import AddNewController from 'app/client/core/js/controllers/addNew.js';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory.js';
import Dialog from 'app/client/core/js/modules/dialog.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Dialog'], Dialog],
  [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Dialog'], Dialog]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="top-bar">Save as New Template</div>'+'<div data-controller="AddNewStory">'+'  <form>'+'    <div class="form-input" data-validate="notEmpty">'+'      <label for="template-name">Template Name</label>'+'      <input id="template-name" class="textfield" type="text" data-on-keydown="createTemplateKeydown" data-tabindex/>'+'    </div>'+''+'    ';if (d.showStoryLinkWarning) {str += '      <div class="form-help-message warning">'+'        <span class="help-icon fa fa-warning"></span>'+'        Oops - Story Relationships aren\'t supported as part of Story Templates just yet, but this feature is coming soon!'+'      </div>'+'    ';}str += '    <div class="form-help-message">'+'      <span class="help-icon fa fa-info-circle"></span>Story Templates save your title, description, and all of your selected fields and metadata. The only exception is Requester, which will always default to you.'+'    </div>'+''+'    <div class="form-actions">'+'      <button class="action flat-white" data-on-click="App.Dialog.close">Back</button>'+'      <button class="action green" data-on-click="createNewTemplate">Save as New Template</button>'+'    </div>'+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
