import StoryController from 'app/client/core/js/controllers/story.js';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog.js';
import StoryModel from 'app/client/core/js/models/story.js';
import Dialog from 'app/client/core/js/modules/dialog.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryDialog'], StoryDialogController],
  [['Model', 'Story'], StoryModel],
  [['Dialog'], Dialog],
  [['Tooltip'], Tooltip],
  [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryDialog'], StoryDialogController],
  [['Model', 'Story'], StoryModel],
  [['Dialog'], Dialog],
  [['Tooltip'], Tooltip]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';const { activity } = App.Controller.StoryDialog.parseStoryURL(d.href);str += '';if (App.Model.Story.isValidForAutoLink(d)) {str += '  ';if (d.deleted) {str += '    <span class="auto-story-link story-was-deleted" data-tooltip="Story has been deleted."><span class="fa ';str += _CfS(d.story_type_icon);str += '"></span>';str += _CfS(d.id);if (App.Model.Story.isDoneState(d)) {str += '<span class="fa fa-check"></span>';}str += '</span>'+'  ';} else {str += '    <a href="';str += _CfS(App.Model.Story.generateStoryURL(d));str += _CfS( activity ? `#activity-${activity}` : '' );str += '" data-model="Story" data-id="';str += _CfS(d.id);str += '" data-controller="StoryDialog" data-on-click="open" data-tooltip data-tooltip-fn="App.Controller.Story.renderStoryTooltip" data-tooltip-delay="1" class="auto-story-link story-link-';str += _CfS(d.story_type);str += ' ';if (d.archived) {str += 'is-archived';}str += '"><span class="fa ';str += _CfS(d.story_type_icon);str += '"></span>';str += _CfS(d.id);if (App.Model.Story.isDoneState(d)) {str += '<span class="fa fa-check"></span>';}if (d.archived) {str += ' <span class="fa fa-archive"></span>';}if (activity) {str += ' (comment)';}str += '</a>'+'  ';}str += '';} else if (d && !_.isPlainObject(d)) {str += '  <span class="auto-story-link story-was-deleted" data-tooltip="Story has been deleted.">';str += _CfS(d);str += '</span>';} else {str += '  <em>Unknown Story</em>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
