
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<label for="orgs-list">Please select which workspace you would like to authorize </label>'+'<div class="styled-select">'+'  <select data-on-change="updateOrg" id="orgs-list">'+'    ';if (!d.activeOrgId) {str += '      <option>None</option>'+'    ';}str += '    ';_ds.push(d); var _d0 = d.organizations; for (var _i0 = 0, _len0 = (d.organizations || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var org = _d0[_i0];str += '      <option value="';str += _CfS(org.id);str += '"';if (d.activeOrgId === org.id) {str += ' selected="selected"';}str += '>';str += _CfS(org.name);str += '</option>'+'    ';} d = _ds.pop();str += '  </select>'+'  <span class="fa fa-caret-down"></span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
