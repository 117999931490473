import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html';
import * as SettingsSubNavTemplate from 'app/client/settingsShared/views/templates/settingsSubNav.html';import EditProfileController from 'app/client/settingsShared/js/controllers/editProfile.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'EditProfile'], EditProfileController],
  [['Controller', 'EditProfile'], EditProfileController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="account-settings-form" class="settings-pane ';if (!d.withinOrg) {str += 'settings-organizations';}str += '">'+''+'  <div class="clearfix">'+''+'    ';if (!d.withinOrg) {str += '    <div class="left-column">'+'      ';str += SettingsSubNavTemplate.render( d);str += '    </div>'+'    ';}str += '    <div class="right-column">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "My Profile"});str += '      <div class="form" data-controller="EditProfile">'+''+'        <div id="waiting-for-profile-content">'+'          ';str += InlineLoaderTemplate.render( d);str += '        </div>'+'      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
