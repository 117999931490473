import HelpController from 'app/client/core/js/controllers/help.js';
import Url from 'app/client/core/js/modules/url.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Help'], HelpController],
  [['Url'], Url],
  [['Controller', 'Help'], HelpController],
  [['Url'], Url]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="page-explanation">'+'  <h3>About The Created vs. Completed Chart</h3>'+''+'  <div class="chart-explanation">'+'    <img src="';str += _CfS( d.imgSrc );str += '" alt="Example Created vs. Completed Chart" />'+''+'    <p>Working Days will be supported soon.</p>'+'    '+'    <p>'+'      The Created vs. Completed chart displays work created and completed within a period of time. Work is represented by either points or Story count.'+'    </p>'+''+'    <p>'+'      The x-axis represents time; the intervals can be changed by using the Group By filter at the top of the page. Note that Group By Iteration is not supported by this chart.'+'    </p>'+''+'    <p>'+'      The y-axis represents the number of points or Stories, which can be configured within the chart via the dropdown on the top-right.'+'    </p>'+''+'    <p>'+'      "Stories Created" measures points or story counts for all stories created during that interval. "Stories Completed" measures points or story counts for all stories that moved to a "Completed" workflow state during that interval.'+'    </p>'+'  </div>'+''+'  <div class="still-have-questions">'+'    Any questions? <a href="';str += _CfS(App.Controller.Help.KNOWLEDGE_BASE_SLUGS.HELP_HOME);str += '" target="_blank">Check out our Help Center documentation <span class="fa fa-external-link"></span></a> or <a href="';str += _CfS(App.Url.getSlugPath());str += '/help" data-controller="Help" data-on-click="open">get in touch!</a>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
