import ImportModel from 'app/client/core/js/models/import.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Import'], ImportModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



export function render(_d) {
  let html = '<div id="organization-settings" class="settings-pane">  <div class="clearfix">    <div class="right-column" data-model="Import">      <div class="import-data-container" data-component-property-key="importData" data-component-key="import-data"></div>    </div>  </div></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
