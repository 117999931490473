import LabelModel from 'app/client/core/js/models/label.js';
import Emoji from 'app/client/core/js/modules/emoji.js';
import Format from 'app/client/core/js/modules/format.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Label'], LabelModel],
  [['Emoji'], Emoji],
  [['Format'], Format],
  [['Model', 'Label'], LabelModel],
  [['Emoji'], Emoji],
  [['Format'], Format]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';_ds.push(d); var _d0 = App.Model.Label.sortByLowercaseName(d.labels); for (var _i0 = 0, _len0 = (App.Model.Label.sortByLowercaseName(d.labels) || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var label = _d0[_i0];str += '  ';label = App.Model.Label.get({ id: label.id }) || App.Model.Label.get({ name: label.name }) || label;str += '  <div class="tag';if (label.archived) {str += ' archived';}str += '" data-model="Label" data-id="';str += _CfS(label.id);str += '" ';if (d.onClick) {str += 'data-on-click="';str += _CfS(d.onClick);str += '"';}str += ' ';if (label.archived) {str += ' data-tooltip="This Label is archived"';}str += ' tabindex="0">'+'    <span class="color fa fa-circle" style="color: ';str += _CfS(label.color || App.Model.Label.getDefaultColor());str += '"></span>'+'    ';str += (label.formatted_name || App.Format.sanitizeAndEmojify(label.name));str += '    ';if (d.contextMenu) {str += '      <span class="more-items fa fa-ellipsis-h" data-tooltip="Show Label options" data-context-menu="';str += _CfS(d.contextMenu);str += '" data-context-menu-right="-5" data-context-menu-top="-4" id="label-context-';str += _CfS(label.id);str += '-';str += _CfS(_.random(label.id));str += '"></span>'+'    ';} else if (d.onRemove) {str += '      <span class="delete fa fa-times" data-on-click="';str += _CfS(d.onRemove);str += '" data-tooltip="Remove Label"></span>'+'    ';}str += '  </div>';} d = _ds.pop();str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
