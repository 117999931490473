import ProjectModel from 'app/client/core/js/models/project.js';
import TeamModel from 'app/client/core/js/models/team.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import ProjectController from 'app/client/stories/js/controllers/project.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Project'], ProjectModel],
  [['Model', 'Team'], TeamModel],
  [['Tooltip'], Tooltip],
  [['Controller', 'Project'], ProjectController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<div class="projects unselectable" data-controller="Project">'+'  ';if (d.projects && d.projects.length > 0) {str += '    ';_ds.push(d); var _d1 = d.projects; for (var _i1 = 0, _len1 = (d.projects || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var project = _d1[_i1];str += '      <div data-id="';str += _CfS(project.id);str += '" class="project-parent relative ';if (project.archived) {str += 'archived-project';}str += '">'+'        <div class="project list-nav-item ';if (project.active) {str += 'active';}str += '" id="project-';str += _CfS(project.id);str += '" data-model="Project" data-id="';str += _CfS(project.id);str += '" data-on-click="toggle" data-context-menu="contextMenuItems" ';if (project.name.length > 16) {str += 'data-tooltip data-tooltip-fn="App.Controller.Project.projectSidebarTooltip"';}str += '>'+'          <span class="fa ';if (project.active) {str += 'fa-check-square';} else {str += 'fa-square-o';}str += '" style="color: ';str += _CfS(project.color);str += '"></span>'+'          <span class="name">';str += _CfS(project.name);str += '</span>'+'        </div>'+'      </div>'+'    ';} d = _ds.pop();str += '  ';} else {str += '    <div class="no-projects-in-team">No Projects in Selected ';str += _CfS( App.Model.Team.getLabel() );str += '</div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
