
export function render(_d) {
  let html = '<div class="search-by-date-tooltip">  <div class="tooltip-title">Search By Date</div>  <div class="tooltip-description">Date operators can accept 3 types of values:</div>  <div>    <table>      <tr class="value-type">        <td class="name">Dates</td>        <td><span class="value">2018-05-31</span></td>      </tr>      <tr class="value-type">        <td class="name">Date Ranges</td>        <td>          <span class="value"> 2018-04-30..2018-05-15</span>          <span class="value"> *..2018-04-30</span>          <span class="value"> 2018-04-30..*</span>        </td>      </tr>      <tr class="value-type">        <td class="name">Keywords</td>        <td>          <span class="value"> yesterday</span>          <span class="value"> today</span>          <span class="value"> tomorrow</span>        </td>      </tr>    </table>  </div></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
