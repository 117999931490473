import * as IconIterationTemplate from 'app/client/core/views/templates/icons/icon-iteration.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div>Previously Started Iterations:</div>';_ds.push(d); var _d0 = d.iterations; for (var _i0 = 0, _len0 = (d.iterations || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var iteration = _d0[_i0];str += '<p>'+'  ';str += IconIterationTemplate.render( { status: iteration.status });str += '  ';str += _CfS( iteration.name );str += '</p>';} d = _ds.pop();str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
