
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<!-- <div class="change-icon change-icon-green">'+'  <span class="fa fa-plus"></span>'+'</div> -->'+''+'<div class="value">'+'  ';if (d.isOwner) {str += '    <strong>';str += (d.name);str += '</strong> just joined ';str += _CfS(d.orgName);str += '. Note that this additional user may affect your bill. See your current number of seats and other billing info <a href="/';str += _CfS(d.orgSlug);str += '/settings/billing">here</a>.'+'  ';}str += '  ';if (!d.isOwner) {str += '    Good news! ';str += _CfS(d.name);str += ' just now joined your ';str += _CfS(d.workspaceName);str += ' workspace.'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
