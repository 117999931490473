
export function render(_d) {
  let html = '<div id="sign-up-successful">    <span class="hidden" data-component-property-key="stackOverflowPixel" data-component-key="stackoverflow-pixel"></span>    <span data-component-property-key="accountCreated" data-component-key="account-created"></span></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
