
export function render(_d) {
  let html = '<span class="custom-icon icon-story">    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path      d="M28.12 5H3.88A2.89 2.89 0 0 0 1 7.85v17.3A2.89 2.89 0 0 0 3.88 28h24.24A2.89 2.89 0 0 0 31 25.15V7.85A2.89 2.89 0 0 0 28.12 5zm.58 20.19a.58.58 0 0 1-.58.58H6.77V7.27h21.35a.58.58 0 0 1 .58.58v17.3z"/><path      d="M10.23 10.73h13.85v2.31H10.23zm0 4.62h10.38v2.31H10.23z"/></svg>  </span>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
