import InviteModel from 'app/client/core/js/models/invite.js';
import User from 'app/client/core/js/modules/user.js';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Invite'], InviteModel],
  [['User'], User],
  [['Controller', 'InviteUsers'], InviteUsersController],
  [['Controller', 'InviteUsers'], InviteUsersController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<h3>Invited Users</h3>';if (d.readOnly) {str += '<div class="read-only">';}str += '  ';_ds.push(d); var _d0 = d.invites; for (var _i0 = 0, _len0 = (d.invites || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var invite = _d0[_i0];str += '    <div class="existing-invite" data-model="Invite" data-id="';str += _CfS(invite.id);str += '">'+'      <div class="person-icon">'+'        ';str += (App.Controller.InviteUsers.renderUserIcon(invite.id, d.tabId));str += '      </div>'+'      <div class="existing-invite-details">'+'        <a href="mailto:';str += _CfS(invite.email);str += '" class="invite-email-link">';str += _CfS(invite.email);str += '</a>'+'        <div class="existing-invite-info">'+'          <span class="tagged blue">';str += _CfS(_.upperFirst(invite.role));str += '</span>'+'          <span class="invited-at">Invited ';str += _CfS(moment(invite.created_at).fromNow());str += '</span>'+'        </div>       '+'      </div>      '+'      <div class="existing-invite-actions">'+'        ';if (!d.readOnly) {str += '          ';if (d.showResendButton) {str += '            <a href="#" class="resend-invite" data-on-click="resendInvite" data-tooltip="Re-send invite">';str += (App.Controller.InviteUsers.renderSyncIcon(invite.id, d.tabId));str += '</a>'+'          ';}str += '          <a href="#" class="delete-invite" data-on-click="deleteInvite" data-tooltip="Delete invite">';str += (App.Controller.InviteUsers.renderTrashIcon(invite.id, d.tabId));str += '</a>'+'        ';}str += '      </div>'+'    </div>'+'  ';} d = _ds.pop();str += '  ';if (d.invites.length === 0) {str += '    <div class="no-invites-found">No active invites found.</div>'+'  ';}str += '';if (d.readOnly) {str += '</div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
