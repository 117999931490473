import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html';import Utils from 'app/client/core/js/modules/utils.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Utils'], Utils],
  [['Utils'], Utils]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<table class="c3-tooltip">'+'  <tr>'+'    <th colspan="2" class="c3-tooltip-title">';str += _CfS(d.title);str += '</th>'+'  </tr>'+'  ';_ds.push(d); var _d0 = d.rows; for (var _i0 = 0, _len0 = (d.rows || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '    <tr class="c3-tooltip-name--';str += _CfS(App.Utils.slugify(d.name));str += '">'+'      <td class="name">'+'        ';if (d.story) {str += '          ';str += StoryAutoLinkTemplate.render( d.story);str += '        ';} else {str += '          <span style="background-color: ';str += _CfS(d.color);str += '"></span>'+'        ';}str += '        ';str += _CfS(d.name);str += '      </td>'+'      <td class="value">';str += _CfS(d.value);str += '</td>'+'    </tr>'+'  ';} d = _ds.pop();str += '</table>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
