import CommentController from 'app/client/core/js/controllers/comment.js';
import CommentModel from 'app/client/core/js/models/comment.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel],
  [['Controller', 'Comment'], CommentController],
  [['Model', 'Comment'], CommentModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<a href="#" class="action micro flat-white" data-controller="Comment" data-on-click="toggleCommentOrder">'+'  ';if (App.Model.Comment.commentsAreAscending()) {str += '    <span class="is-ascending fa fa-arrow-down"></span> Oldest first'+'  ';} else {str += '    <span class="is-descending fa fa-arrow-up"></span> Newest first'+'  ';}str += '</a>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
