
export function render(_d) {
  let html = '<div class="referral-complex-reward">    <div class="referral-complex-reward-item">      <p class="referral-complex-reward-title">Limited edition sticker pack</p>      <p class="referral-complex-reward-subtitle"><b>1</b> Referral</p>    </div>    <div class="referral-complex-reward-item">      <p class="referral-complex-reward-title">Shortcut socks</p>      <p class="referral-complex-reward-subtitle"><b>3</b> Referrals</p>    </div>    <div class="referral-complex-reward-item">      <p class="referral-complex-reward-title">Shortcut holiday sweater</p>      <p class="referral-complex-reward-subtitle"><b>5</b> Referrals</p>    </div></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
