import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html';import SampleWorkspaceController from 'app/client/core/js/controllers/sampleWorkspace.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'SampleWorkspace'], SampleWorkspaceController],
  [['Controller', 'SampleWorkspace'], SampleWorkspaceController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="inline-user-mention-tooltip">'+'  ';str += UserAvatarTemplate.render( { profile: d.profile, size: 64 });str += '  <div class="user-info">'+'    <p class="user-name">';str += _CfS(d.profile.name);str += '</p>'+'    ';if (!App.Controller.SampleWorkspace.isSampleObserver()) {str += '      <p class="note">';str += _CfS(d.profile.email_address);str += '</p>'+'    ';}str += '    <p class="user-mention-username">@';str += _CfS(d.profile.mention_name);str += '</p>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
