import * as BillingCardSummaryTemplate from 'app/client/settingsShared/views/templates/billingCardSummary.html';
import * as BillingPlanSummaryTemplate from 'app/client/settingsShared/views/templates/billingPlanSummary.html';import ProfileModel from 'app/client/core/js/models/profile.js';
import ManageBillingController from 'app/client/settingsShared/js/controllers/manageBilling.js';
import ManageBillingEmailController from 'app/client/settingsShared/js/controllers/manageBillingEmail.js';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard.js';
import ManagePaymentPlanController from 'app/client/settingsShared/js/controllers/managePaymentPlan.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Profile'], ProfileModel],
  [['Controller', 'ManageBilling'], ManageBillingController],
  [['Controller', 'ManageBillingEmail'], ManageBillingEmailController],
  [['Controller', 'ManageCard'], ManageCardController],
  [['Controller', 'ManagePaymentPlan'], ManagePaymentPlanController],
  [['Controller', 'ManageBilling'], ManageBillingController],
  [['Controller', 'ManageBillingEmail'], ManageBillingEmailController],
  [['Controller', 'ManageCard'], ManageCardController],
  [['Controller', 'ManagePaymentPlan'], ManagePaymentPlanController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="subhead plan-summary">Plan Summary';if (!d.readOnly) {str += ' <a href="#" class="action micro flat-white" data-controller="ManagePaymentPlan" data-on-click="open">Edit</a>';}str += '</div>';str += (App.Controller.ManageBilling.renderBillingAlerts());str += '';str += BillingPlanSummaryTemplate.render( _.assign({}, d, { shouldShowSeatsRemaining: true }));str += '<div class="subhead">Billing Email';if (!d.readOnly) {str += ' <a href="#" class="action micro flat-white" data-controller="ManageBillingEmail" data-on-click="open">Edit</a>';}str += '</div>'+''+'<div class="blurb">The billing contact for the <strong>';str += _CfS(d.company.name);str += '</strong> organization is <strong>';str += _CfS(_.get(d, 'paymentPlan2.billing_contact') || App.Model.Profile.getOwnerEmail());str += '</strong>.</div>'+''+'<div class="subhead">Invoice Information (Optional)';if (!d.readOnly) {str += ' <a href="#" class="action micro flat-white" data-controller="ManageBilling" data-on-click="openAdditonalBillingInfo">Edit</a>';}str += '</div>'+''+'<div class="blurb">'+'  ';str += '  ';if (d.paymentPlan2.address) {str += '    Your billing address is <strong>';str += _CfS(d.paymentPlan2.address);str += '</strong>';if (d.paymentPlan2.vat_code) {str += ', and your VAT ID is <strong>';str += _CfS(d.paymentPlan2.vat_code);str += '</strong>';}str += '.'+'  ';} else {str += '    ';if (d.paymentPlan2.vat_code) {str += 'Your VAT ID is <strong>';str += _CfS(d.paymentPlan2.vat_code);str += '</strong>, and you';} else {str += 'You';}str += ' don\'t have a billing address set.'+'  ';}str += '</div>';if (!d.hideCreditCardTab && !d.readOnly) {str += '  <div class="subhead">Current Card <a href="#" class="action micro flat-white" data-controller="ManageCard" data-on-click="open">Edit</a></div>'+'  ';str += BillingCardSummaryTemplate.render( d);str += '';}return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
