import ManageSessionsController from 'app/client/settingsShared/js/controllers/manageSessions.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'ManageSessions'], ManageSessionsController],
  [['Controller', 'ManageSessions'], ManageSessionsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



export function render(_d) {
  let html = '<div class="form" data-controller="ManageSessions">  <div id="manage-sessions-form">    <div class="form-help-message">      <span class="help-icon fa fa-info-circle"></span>      Each device you log in from establishes a session.      Normally when you log out, you only log out on that device.      Log out everywhere invalidates all sessions.      If you no longer have access to a device,      it is advised to log out everywhere to ensure nobody can access your data.    </div>    <button class="action" data-on-click="logoutEverywhere" data-tabindex>      Logout Everywhere    </button>  </div></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
