
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.color) {str += '  <span class="color-box" style="background: ';str += _CfS(d.color);str += '"></span>'+'  ';} else {str += '  <span class="color-box color-box--no-project"></span>';}str += '<span data-testid="attribute-name" class="name">Project</span>'+'<span data-testid="attribute-value" class="value">';if (d.raw_name) {str += '<em>';str += _CfS(d.raw_name);str += '</em>';} else if (d.name) {str += _CfS(d.name);} else {str += '<em>None</em>';}str += '</span>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
