
export function render(_d) {
  let html = '<div class="referrals-tooltip">  <h3>What do these numbers mean?</h3>  <ul>    <li><em>Referrals Pending</em> are the number of trials started using your referral codes.</li>    <li><em>Referrals Achieved</em> are the number of referred Organizations that have successfully paid 3 months of positive invoices.</li>  </ul></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
