import NotificationsController from 'app/client/core/js/controllers/notifications.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Notifications'], NotificationsController],
  [['Controller', 'Notifications'], NotificationsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';_ds.push(d); var _d0 = d.activityFilters; for (var _i0 = 0, _len0 = (d.activityFilters || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var activityFilter = _d0[_i0];str += '  <a href="#" class="tab ';if (activityFilter.id === d.currentFilter) {str += 'active';}str += '" data-on-click="tab" data-filter-id="';str += _CfS(activityFilter.id);str += '" data-on-tab-focus="App.Controller.Notifications.updateNotificationFilter">'+'    <span class="name">';str += _CfS(activityFilter.name);str += '</span>'+'    <span class="short-name">';str += _CfS(activityFilter.shortName);str += '</span>'+'  </a>';} d = _ds.pop();str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
