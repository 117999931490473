import StoryController from 'app/client/core/js/controllers/story.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Story'], StoryController],
  [['Controller', 'Story'], StoryController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.added) {str += 'associated';} else {str += 'disassociated';}str += '';if (d.branch.name) {str += '  the branch'+'  <a href="';str += _CfS(d.branch.url);str += '" target="_blank">';str += _CfS(d.branch.name);str += '</a>';} else {str += '  a branch';}str += '';if (d.added) {str += ' with';} else {str += ' from';}str += ' this Story';if (d.showRestore) {str += '  <span class="history-action">'+'    <a href="#" class="action micro flat-white" data-controller="Story" data-on-click="associateBranch" data-id="';str += _CfS(d.branch.id);str += '" data-tooltip="Reassociate this branch to this Story.">Restore</a>'+'  </span>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
