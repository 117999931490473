import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html';import EditOrganizationController from 'app/client/settingsShared/js/controllers/editOrganization.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'EditOrganization'], EditOrganizationController],
  [['Controller', 'EditOrganization'], EditOrganizationController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="organization-settings" class="settings-pane">'+'  '+'  <div class="clearfix">'+'    '+''+'    <div class="right-column">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "General Settings"});str += '      <div class="form" data-controller="EditOrganization">'+'        ';if (d.readOnly) {str += '          <div data-component-property-key="readOnlyMessage" data-component-key="read-only-message"></div>'+'          <div class="read-only">'+'        ';}str += '        <div class="waiting-for-content">'+'          ';str += InlineLoaderTemplate.render( d);str += '        </div>'+''+'        ';if (d.readOnly) {str += '</div>';}str += '      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
