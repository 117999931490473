
export function render(_d) {
  let html = '<button data-on-click="save" class="action green create-button" data-tabindex>Create Iteration <span class="fa fa-arrow-right"></span></button>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
