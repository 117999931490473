
export function render(_d) {
  let html = '<a href="#" class="cancel-new-task action micro white" data-on-click="cancelNewTask" class="cancel">Cancel</a><a href="#" class="save-new-task action micro green" data-on-click="saveNewTask" class="save"><span class="fa fa-level-down fa-rotate-90"></span> Save New Task</a>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
