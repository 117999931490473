import IconModel from 'app/client/core/js/models/icon.js';
import Loading from 'app/client/core/js/modules/loading.js';
import EditProfileController from 'app/client/settingsShared/js/controllers/editProfile.js';
import ManageLoadingIconsController from 'app/client/settingsShared/js/controllers/manageLoadingIcons.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Icon'], IconModel],
  [['Loading'], Loading],
  [['Controller', 'EditProfile'], EditProfileController],
  [['Controller', 'ManageLoadingIcons'], ManageLoadingIconsController],
  [['Controller', 'EditProfile'], EditProfileController],
  [['Controller', 'ManageLoadingIcons'], ManageLoadingIconsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="polaroid" id="loading-icon-';str += _CfS(d.id);str += '" data-model="Icon" data-id="';str += _CfS(d.id);str += '" data-controller="ManageLoadingIcons" data-context-menu="contextMenuItems" data-context-menu-right="-75">'+'  <div class="polaroid-background">'+'    <span class="image-wrapper ';if (d.spin) {str += 'fa fa-spin-slow';}str += '"><img src="';str += _CfS(d.url);str += '" alt="';str += _CfS(d.message);str += '" /></span>'+'    <div class="message filename" data-tooltip="Click to Edit Loading Message" data-tooltip-delay="200" data-on-click="editMessage" data-key="message" data-on-save="updateMessage" data-on-cancel="cancelMessage" data-multiline="false">'+'      <span class="align-center">';str += _CfS(d.message || App.Loading.getDefaultLoadingState().message);str += ' ';str += (App.Controller.EditProfile.renderPencilIcon(d.id));str += '</span>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
