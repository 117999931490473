import * as StoryAutoLinkWithNameTemplate from 'app/client/core/views/templates/storyAutoLinkWithName.html';import StoryModel from 'app/client/core/js/models/story.js';
import StoryLinkModel from 'app/client/core/js/models/storyLink.js';
import View from 'app/client/core/js/modules/view.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Story'], StoryModel],
  [['Model', 'StoryLink'], StoryLinkModel],
  [['View'], View],
  [['Model', 'Story'], StoryModel],
  [['Model', 'StoryLink'], StoryLinkModel],
  [['View'], View]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var relatedStory = App.Model.StoryLink.getRelatedStory(d);str += '';if (relatedStory) {str += '  <li class="story-relationship ';str += _CfS(App.Model.StoryLink.getClassName(d));str += ' ';if (relatedStory.archived) {str += 'story-link-is-archived';}str += '" data-verb="';str += _CfS(d.verb);str += '" data-related-story-id="';str += _CfS(relatedStory.id);str += '">'+'    <span class="icon fa ';str += _CfS(App.Model.StoryLink.getIcon(d));str += '"></span>'+'    <div class="middle">'+'      ';str += _CfS(App.Model.StoryLink.getRelativeVerb(d, d.isSubject));str += '      ';str += StoryAutoLinkWithNameTemplate.render( relatedStory);str += '    </div>'+'    <div class="modify" data-component-key="modifyStoryRelationship-';str += _CfS(App.Model.StoryLink.getKey(d));str += '" data-component-property-key="modifyStoryRelationship" data-component-props="';str += _CfS( App.View.serializeProps({ storyLinkKey: App.Model.StoryLink.getKey(d) }) );str += '"></div>'+'  </li>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
