import EpicModel from 'app/client/core/js/models/epic.js';
import View from 'app/client/core/js/modules/view.js';
import EpicPageController from 'app/client/epics/js/controllers/epicPage.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Epic'], EpicModel],
  [['View'], View],
  [['Controller', 'EpicPage'], EpicPageController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div'+'  class="epic-page"'+'  data-controller="EpicPage"'+'  data-model="Epic"'+'  data-id="';str += _CfS(d.id);str += '"'+'  data-component-property-key="epicPage" '+'  data-component-key="epicPage" '+'  data-component-props="';str += _CfS( App.View.serializeProps(d) );str += '"'+'></div>'+'<!-- '+'  This div is currently required because the `EpicPageComments`-controller won\'t be included if the Caveman build can\'t find a reference to it.'+'  Once the comments section is rewritten in React, and there is no need for this controller, this can be removed.'+'-->'+'<div data-controller="EpicPageComments"></div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
