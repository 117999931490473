
export function render(_d) {
  let html = '<div class="autocomplete-footer-tip">Followers are notified of story activity. Story requesters and owners are automatically added as followers.</div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
