import EpicModel from 'app/client/core/js/models/epic.js';
import EpicStateModel from 'app/client/core/js/models/epicState.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Epic'], EpicModel],
  [['Model', 'EpicState'], EpicStateModel],
  [['Model', 'Epic'], EpicModel],
  [['Model', 'EpicState'], EpicStateModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var epicState = _.get(d, 'stateObject.type');str += '';if (epicState === 'started') {str += '  ';str += (App.Model.EpicState.renderStartedIcon(18));str += '';} else if (epicState === 'done') {str += '  ';str += (App.Model.EpicState.renderDoneIcon(18));str += '';} else {str += '  ';str += (App.Model.EpicState.renderUnstartedIcon(18));str += '';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
