
export function render(_d) {
  let html = '<div class="clearfix password-strength" data-tooltip="Password Strength">  <span class="bar bar-1"></span>  <span class="bar bar-2"></span>  <span class="bar bar-3"></span>  <span class="bar bar-4"></span></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
