
export function render(_d) {
  let html = '<div class="story-has-no-history">  <span class="fa fa-clock-o"></span>  <h3>Activity from 2016 is unavailable.</h3>  <p>Our activity feed relies on a data formatting change that was made on January 9, 2017.</p></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
