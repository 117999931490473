
export function render(_d) {
  let html = '<div class="stacked-attribute" data-component-property-key="AddStoryRequesterField"  data-component-key="AddStoryRequesterField"></div><div class="stacked-attribute" data-component-property-key="AddStoryOwnerField"  data-component-key="AddStoryOwnerField"></div>';
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
}
