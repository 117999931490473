import TooltipController from 'app/client/core/js/controllers/tooltip.js';
import ProfileModel from 'app/client/core/js/models/profile.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import User from 'app/client/core/js/modules/user.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Tooltip'], TooltipController],
  [['Model', 'Profile'], ProfileModel],
  [['Tooltip'], Tooltip],
  [['User'], User],
  [['Controller', 'Tooltip'], TooltipController],
  [['Model', 'Profile'], ProfileModel],
  [['Tooltip'], Tooltip],
  [['User'], User]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += ' <span class="inline-mention mention-found" data-model="Profile" data-id="';str += _CfS(d.profile.id);str += '" data-tooltip data-tooltip-fn=\'App.Controller.Tooltip.renderUserTooltip\'>@';str += _CfS(d.profile.mention_name);str += '</span>';str += _CfS(d.extraChar);str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
