import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html';import AddExternalLinkController from 'app/client/core/js/controllers/addExternalLink.js';
import StoryModel from 'app/client/core/js/models/story.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'AddExternalLink'], AddExternalLinkController],
  [['Model', 'Story'], StoryModel],
  [['Controller', 'AddExternalLink'], AddExternalLinkController],
  [['Model', 'Story'], StoryModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="external-link-header">'+'  Add the External URL to be linked to'+'  ';if (d.id) {str += '    the Story ';str += StoryAutoLinkTemplate.render( d.story);str += '  ';}str += '</div>'+''+'<div class="external-link-body" data-controller="AddExternalLink" data-model="Story" data-id="';str += _CfS(d.id);str += '" data-validate="isUrl">'+'  <form data-on-submit="linkStoryToExternalLink">'+'    <input type="text" id="external-link-url" class="textfield" placeholder="';str += _CfS(d.placeholder);str += '" />'+'    <button type="submit" class="action micro green">Link to ';str += _CfS(d.type);str += '</button>'+'  </form>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
