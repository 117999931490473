import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html';import ManageTemplatesController from 'app/client/settingsShared/js/controllers/manageTemplates.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'ManageTemplates'], ManageTemplatesController],
  [['Controller', 'ManageTemplates'], ManageTemplatesController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="manage-templates" class="settings-pane">'+''+'  <div class="clearfix">'+'    '+''+'    <div class="right-column">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "Manage Story Templates"});str += '      <div class="form" data-controller="ManageTemplates">'+''+'        <div class="blurb">'+'          <p>'+'            Story Templates help your team create Stories using a consistent, standardized format. Some examples might be customer feature requests or bug templates.'+'          </p>'+'        </div>'+''+'        <div class="server-messages"></div>'+''+'       '+''+'        ';if (d.isObserver) {str += '          <div data-component-property-key="readOnlyMessage" data-component-key="read-only-message"></div>'+'        ';} else if (!d.isAdmin) {str += '          <div data-component-property-key="readOnlyMessage" data-component-key="read-only-message"></div>'+'        ';}str += '        <div class="manage-template-controls">'+'          ';str += InlineLoaderTemplate.render( d);str += '        </div>'+''+'      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
